import { watch, WatchStopHandle } from 'vue'
import { RouterScrollBehavior } from 'vue-router'
import { useViewLoadingStore } from '@/modules/base/stores/useViewLoadingStore'
import { preventScroll } from './scrollBehaviorPreventScroll'
import { handleSavedPosition } from './scrollBehaviorSavedPosition'
import { scrollTo } from './scrollBehaviorScrollTo'

export const scrollBehavior: RouterScrollBehavior = async (
  to,
  from,
  savedPosition
) => {
  return (
    preventScroll(to, from) ??
    (await waitForStopLoading()) ??
    handleSavedPosition(to, from, savedPosition) ??
    (await scrollTo(to))
  )
}

const scrollingCancellation = false
const scrollingContinuation = undefined

/**
 * Wait for the current view to load before continuing
 * @returns Promise that will resolve when the view is loaded
 */
const waitForStopLoading = () =>
  new Promise<ReturnType<RouterScrollBehavior>>((resolve) => {
    const viewLoadingStore = useViewLoadingStore()
    // Prevent ReferenceError
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unwatch: WatchStopHandle = () => {}
    unwatch = watch(
      () => viewLoadingStore.loadingKey,
      (newKey, oldKey) => {
        // if the key changes, another view has started loading and we cancel the scrolling
        if (oldKey && newKey) {
          unwatch()
          resolve(scrollingCancellation)
        }
        // if the new key is null, the view has finished loading and we continue scrolling
        else if (!newKey) {
          unwatch()
          resolve(scrollingContinuation)
        }
        // otherwise a view just started loading and we wait for it to finish
      },
      {
        immediate: true,
      }
    )
  })
